export const tools = {
  bold: {
    name: "bold",
    icon: "fal fa-bold",
    toggleKey: "toggleBold",
    component: "baseTool"
  },
  italic: {
    name: "italic",
    icon: "fal fa-italic",
    toggleKey: "toggleItalic",
    component: "baseTool"
  },
  strike: {
    name: "strike",
    icon: "fal fa-strikethrough",
    toggleKey: "toggleStrike",
    component: "baseTool"
  },
  code: {
    name: "code",
    icon: "fal fa-code",
    toggleKey: "toggleCode",
    component: "baseTool"
  },
  bulletList: {
    name: "bulletList",
    icon: "fal fa-list-ul",
    toggleKey: "toggleBulletList",
    component: "baseTool"
  },
  orderedList: {
    name: "orderedList",
    icon: "fal fa-list-ol",
    toggleKey: "toggleOrderedList",
    component: "baseTool"
  },
  codeBlock: {
    name: "codeBlock",
    icon: "fal fa-rectangle-code",
    toggleKey: "toggleCodeBlock",
    component: "baseTool"
  },
  paragraph: {
    name: "paragraph",
    icon: "fal fa-paragraph",
    toggleKey: "setParagraph",
    component: "baseTool"
  },
  h1: {
    name: "heading",
    icon: "fal fa-h1",
    toggleKey: "toggleHeading",
    params: { level: 1 },
    component: "baseTool"
  },
  h2: {
    name: "heading",
    icon: "fal fa-h2",
    toggleKey: "toggleHeading",
    params: { level: 2 },
    component: "baseTool"
  },
  h3: {
    name: "heading",
    icon: "fal fa-h3",
    toggleKey: "toggleHeading",
    params: { level: 3 },
    component: "baseTool"
  },
  blockQuote: {
    name: "blockquote",
    icon: "fal fa-block-quote",
    toggleKey: "toggleBlockquote",
    component: "baseTool"
  }
};
