<template>
  <div v-if="isEditable" class="row no-gutters">
    <div v-for="(toolSet, i) in toolbar" :key="i" class="col-auto">
      <div class="row no-gutters">
        <div v-for="(tool, j) in toolSet" :key="j" class="col-auto">
          <component
            :is="tools[tool].component"
            :editor="editor"
            :options="tools[tool]"
          />
        </div>
        <v-divider
          v-if="i + 1 < toolbar.length"
          vertical
          class="col-auto mx-3"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { tools } from "@/components/Tools/FormHelper/Components/Editor/Tools/tools";

export default {
  components: {
    baseTool: () =>
      import("@/components/Tools/FormHelper/Components/Editor/Tools/BaseTool")
  },
  props: {
    editor: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      toolbar: [
        ["bold", "italic", "strike", "code"],
        ["bulletList", "orderedList", "blockQuote", "codeBlock"],
        ["paragraph", "h1", "h2", "h3"]
      ]
    };
  },
  computed: {
    tools: function () {
      return tools;
    },
    isEditable: function () {
      return this.editor.isEditable;
    }
  },
  mounted() {},
  methods: {}
};
</script>
